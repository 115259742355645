import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopTimePicker from '@mui/lab/DesktopTimePicker';
import Grid from '@mui/material/Grid';

const ClockList = () => {
  const [now, setNow] = useState(moment());
  const timezones = [
    { label: 'San Francisco / PST', value: 'America/Los_Angeles', flag: '🇺🇸' },
    { label: 'Mazatlán', value: 'America/Mazatlan', flag: '🇲🇽' },
    // { label: 'Chicago / Dallas', value: 'America/Winnipeg' },
    { label: 'Waterloo / NY', value: 'America/New_York', flag: '🇨🇦 🇺🇸' },
    { label: 'UTC', value: 'GMT' },
    // { label: 'India', value: 'Asia/Kolkata' },
    { label: 'Hanoi', value: 'Asia/Ho_Chi_Minh', flag: '🇻🇳' },
    { label: 'Seoul', value: 'Asia/Seoul', flag: '🇰🇷' },
  ];
  useEffect(() => {
    document.title = 'Adjustable World Clock';
  }, []);

  const handleTimeChange = (localTimeMoment) => {
    setNow(localTimeMoment);
  };

  return (
    <div>
      <h1 className="text-3xl font-bold underline">World clocks</h1>
      <Grid container spacing={3} justifyContent="flex-start" direction="row">
        {timezones.map((timezone) => {
          return (
            <Grid key={timezone.value} item xs={12} sm={3} lg={2}>
              <ReactTimePicker
                timezone={timezone}
                now={now}
                onChange={handleTimeChange}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

const ReactTimePicker = ({ timezone, now, onChange }) => {
  const localTime = moment(now).tz(timezone.value);
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <p>
        {localTime.format('dddd').substring(0, 3)}{' '}
        {localTime.format('YYYY-MM-DD')} {timezone.flag}
      </p>
      <DesktopTimePicker
        disableOpen
        label={timezone.label}
        value={localTime}
        minutesStep={5}
        onChange={(event) => onChange(event, timezone)}
        renderInput={(params) => {
          // console.log('params: ', params);
          params.inputProps.readOnly = true;
          // params.inputProps.onMouseEnter = (event) => onMouseEnter(event, timezone);
          // params.inputProps.onMouseLeave = (event) => onMouseLeave(event, timezone);
          return <TextField {...params} />;
        }}
      />
    </LocalizationProvider>
  );
};

export default ClockList;
