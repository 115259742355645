const replaceHourMinString = (dateString, hourMinString) => {
  hourMinString = ('0' + hourMinString).slice(-5);
  return dateString.replace(/T\d{2}:\d{2}:\d{2}/, `T${hourMinString}:00`);
};

const removeAccentFromVowel = (char) => {
  const normalizedChar = char.normalize('NFD').toLowerCase();
  const accentRegex = /[\u0300-\u036f]/g;
  return normalizedChar.replace(accentRegex, '');
};

module.exports = {
  replaceHourMinString,
  removeAccentFromVowel,
};
