import ClockList from "./ClockList";
import DailySpanish from "./DailySpanish";
import Policy from "./Policy";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="app-container my-6">
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<ClockList />} />
          <Route path="/clocks" element={<ClockList />} />
          <Route path="/spanish" element={<DailySpanish />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>

        <div>
          <nav className="navbar">
            <ul className="nav-links">
              <li>
                <Link to="https://spanish-quiz.com" className="nav-link">
                  Spanish Conjugations
                </Link>
              </li>
              <li>
                <Link to="/clocks" className="nav-link">
                  What time is it?
                </Link>
              </li>
              <li>
                <Link to="/policy" className="nav-link">
                  Policies
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </Router>
      <p>
        Please send me an email at{" "}
        <a href="mailto:orgpark@gmail.com">orgpark@gmail.com</a> if you have any
        suggestions.
      </p>
      <p>
        Release version: {process.env.REACT_APP_BUILD_VERSION || "development"}
      </p>
    </div>
  );
}

export default App;
